/* Noto Sans KR 폰트 적용 */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100; /* Thin */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-Thin.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 200; /* Extra Light */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-ExtraLight.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300; /* Light */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-Light.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400; /* Regular (normal) */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-Regular.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500; /* Medium */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-Medium.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 600; /* SemiBold */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-SemiBold.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700; /* Bold */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-Bold.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 800; /* ExtraBold */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-ExtraBold.ttf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900; /* Black */
  font-display: swap;
  src: url("./assets/fonts/NotoSansKR-Black.ttf");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/NotoSansJP-Regular.ttf");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/NotoSansJP-SemiBold.ttf");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/NotoSansJP-Bold.ttf");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500; 
  font-display: swap;
  src: url("./assets/fonts/NotoSansJP-Medium.ttf");
}

.App {
  font-family: "Noto Sans KR";
   color: #333333;
}
